import React, {useState} from "react";
import _ from "lodash";
import {parseResponse} from "../../../utils/api";

/**
 * Fetch portfolio risk return and correlation matrix data.
 * @param customerId Customer identifier
 * @param portfolio Portfolio data
 * @param dataProvider Data provider
 * @param useContext Flag, that indicate if data from context should be used or not.
 * Set to true in case hook wrapped in context provider that already fetch data and it could be reused.
 */
export function useRiskReturnData(customerId, portfolio, dataProvider, withPortfolioReturn=true, startDate, endDate, investmentStrategyId) {

  const [riskReturn, setRiskReturn] = React.useState({
    riskReturnData: undefined,
    riskReturnLoading: true,
    riskReturnError: null,
    updatedAt: +new Date(),
  });

  const [portfolioRiskReturn, setPortfolioRiskReturn] = React.useState({
    portfolioRiskReturnData: undefined,
    portfolioRiskReturnLoading: true,
    portfolioRiskReturnError: null,
    updatedAt: +new Date(),
  });

  const fetchRiskReturnData = () => {
    setRiskReturn({
      riskReturnData: undefined,
      riskReturnLoading: true,
      riskReturnError: null,
      updatedAt: +new Date(),
    });
    dataProvider.getRiskReturn(customerId, portfolio.depotNumberForAssets || portfolio.depotNumber, startDate, endDate)
      .then((response) => {
        parseResponse(response, 'risk_return',
          (data) => {
            setRiskReturn({
              riskReturnError: undefined,
              riskReturnData: data,
              riskReturnLoading: false,
              updatedAt: +new Date()
            })
          },
          (error) => {
            setRiskReturn({
              riskReturnData: undefined,
              riskReturnLoading: false,
              riskReturnError: error,
              updatedAt: +new Date()
            })
          })
      })
      .catch((error) => {
        setRiskReturn({
          riskReturnData: undefined,
          riskReturnLoading: false,
          riskReturnError: error.toString(),
          updatedAt: +new Date()
        });
      })
    if (withPortfolioReturn) {
      dataProvider.getPortfolioRiskReturn(customerId, portfolio.depotNumber, investmentStrategyId)
        .then((response) => {
          parseResponse(response, 'pf_risk_return',
            (data) => {
              setPortfolioRiskReturn({
                portfolioRiskReturnData: data,
                portfolioRiskReturnLoading: false,
                portfolioRiskReturnError: undefined,
                updatedAt: +new Date()
              })
            },
            (error) => {
              setPortfolioRiskReturn({
                portfolioRiskReturnData: undefined,
                portfolioRiskReturnLoading: false,
                portfolioRiskReturnError: error,
                updatedAt: +new Date()
              })
            }
          )
        })
        .catch((error) => {
          setPortfolioRiskReturn({
            portfolioRiskReturnData: undefined,
            portfolioRiskReturnLoading: false,
            portfolioRiskReturnError: error.toString(),
            updatedAt: +new Date()
          })
        })
    }
  }

  React.useEffect(() => {
    if (!!customerId && !!portfolio) {
      fetchRiskReturnData();
    }
  }, [customerId, _.get(portfolio, 'depotNumberForAssets'), _.get(portfolio, 'depotNumber'), startDate, endDate]);

  return {riskReturn, portfolioRiskReturn}

}