import React from "react";
import _ from "lodash";
import {parseResponse} from "../../../utils/api";

/**
 * Fetch portfolio risk return and correlation matrix data.
 * @param customerId Customer identifier
 * @param portfolio Portfolio data
 * @param dataProvider Data provider
 * Set to true in case hook wrapped in context provider that already fetch data and it could be reused.
 * @param startDate
 * @param endDate
 */
export function useCorrelationMatrixData(customerId, portfolio, dataProvider, startDate, endDate) {

  const [correlation, setCorrelation] = React.useState({
    correlationData: undefined,
    correlationLoading: true,
    correlationError: null,
    updatedAt: +new Date(),
  });

  const fetch = () => {
    setCorrelation({
      correlationData: undefined,
      correlationLoading: true,
      correlationError: null,
      updatedAt: +new Date(),
    });
    dataProvider.getCorrelation(customerId, portfolio.depotNumberForAssets || portfolio.depotNumber, startDate || portfolio.start_tracking_date, endDate)
      .then((response) => {
        parseResponse(response, 'correlation_matrix',
          (data) => {
            setCorrelation({
              correlationError: undefined,
              correlationData: data,
              correlationLoading: false,
              updatedAt: +new Date()
            })
          },
          (error) => {
            setCorrelation({
              correlationData: undefined,
              correlationLoading: false,
              correlationError: error,
              updatedAt: +new Date()
            })
          })
      })
      .catch((error) => {
        setCorrelation({
          correlationData: undefined,
          correlationLoading: false,
          correlationError: error.toString(),
          updatedAt: +new Date()
        })
      })
  };

  React.useEffect(() => {
    if (!!customerId && !!portfolio) {
      fetch();
    }
  }, [customerId, _.get(portfolio, 'depotNumberForAssets'), _.get(portfolio, 'depotNumber'), startDate, endDate]);

  return {correlation}

}