import React from "react";
import {withStyles} from "@material-ui/core";
import styles from "./styles";
import moment from "moment";

import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableCell from '@material-ui/core/TableCell';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import {openMorningStartIsin, withPercentOrDash} from "../../../../../utils/utils";
import clsx from "clsx";
import _ from "lodash";
import {getComparator, stableSort} from "../../../../VirtualPortfolioPage/Tabs/CommonAnalysis";
import ErrorMessage from '../../ChartSectionBordered/components/ErrorMessage';
import {DEFAULT_EMPTY_SECTION_MESSAGE, noMSRating} from "../../../../../utils/constants";


class SinglePerformanceTableChart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            order: this.props.order,
            orderBy: this.props.orderBy
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.data && this.props.data !== prevProps.data) {
            this.renderTable()
        }
    }

    formatDate(date, placeholder) {
        placeholder = placeholder === undefined ? '-' : placeholder
        return date ? moment(date).format('DD.MM.YYYY') : placeholder
    }

    renderTableRows() {
        const { classes } = this.props;

        const rows = [];

        let data = this.props.data || [];

        if (data && data.length > 0) {

            stableSort(data, getComparator(this.state.order, this.state.orderBy)).forEach((asset, index) => {

              let link = undefined;

              if (asset.hasOwnProperty('link')) {
                link = asset['link']
              }

                rows.push(
                    <tr key={asset.isin + '_' + index}>

                      <td className={classes.leftAligned}>

                        {link ?
                          <a href={link} target={'_blank'} className={classes.assetName}>
                            {asset.name}
                          </a>
                          :
                          <a onClick={() => {openMorningStartIsin(asset.isin, null)}} className={classes.assetName}>
                            {asset.name}
                          </a>
                        }
                        <br />
                        {asset.isin || '-'} / {asset.wkn || '-'}
                      </td>

                        <td>
                            {withPercentOrDash(asset.weight)}
                        </td>
                        <td>
                            {asset.fund_star_rating_overall ? <Rating
                              name="fund_star_rating_overall"
                              classes={{iconFilled: classes.ratingFilled}}
                              defaultValue={asset.fund_star_rating_overall}
                              emptyIcon={<StarBorderIcon fontSize="inherit" htmlColor={this.props.theme.palette.primary.main}/>}
                              size="small"
                              readOnly
                            /> : noMSRating}
                        </td>
                        <td>
                            {withPercentOrDash(asset.return_3m)}
                        </td>
                        <td>
                            {withPercentOrDash(asset.return_6m)}
                        </td>
                        <td>
                            {withPercentOrDash(asset.return_ytd)}
                        </td>
                        <td>
                            {withPercentOrDash(asset.return_1y)}
                        </td>
                        <td>
                            {withPercentOrDash(asset.return_3y)}
                        </td>
                        <td>
                            {withPercentOrDash(asset.return_5y)}
                        </td>
                        <td>
                            {withPercentOrDash(asset.return_inception)}
                            <div className="sub">{this.formatDate(asset.inception_date, '')}</div>
                        </td>
                    </tr>
                )
            })
        }
        return rows;
    }

    onRequestSort(event, property) {
        const isAsc = this.state.orderBy === property && this.state.order === 'asc';
        this.setState({
            orderBy: property,
            order: isAsc ? 'desc' : 'asc'
        })
    }

    renderTableHeader() {
        const { classes } = this.props;

        const createSortHandler = (property) => (event) => {
            this.onRequestSort(event, property);
        };

        const headlines = [
            { id: 'name', label: 'Produktname', sublabel: 'ISIN/WKN' },
            { id: 'weight', label: 'Gewichtung'},
            { id: 'fund_star_rating_overall', label: 'Morningstar Rating'},
            { id: 'return_3m', label: 'Rendite 3 Mo.'},
            { id: 'return_6m', label: 'Rendite 6 Mo.'},
            { id: 'return_ytd', label: 'Rendite lfd. Jahr'},
            { id: 'return_1y', label: 'Rendite 1 Jahr'},
            { id: 'return_3y', label: 'Rendite 3 Jahre'},
            { id: 'return_5y', label: 'Rendite 5 Jahre'},
            { id: 'return_inception', label: 'Rendite seit Investmentbeginn'},
        ];

        return <tr>
            {headlines.map((headline) => (
                <TableCell
                    key={headline.id}
                    align={headline.id === 'name' ? 'left' : 'right'}
                    sortDirection={this.state.orderBy === headline.id ? this.state.order : false}
                >
                    <TableSortLabel
                      active={this.state.orderBy === headline.id}
                      direction={this.state.orderBy === headline.id ? this.state.order : 'asc'}
                      onClick={createSortHandler(headline.id)}
                    >
                        <div>
                            <div>{headline.label}</div>
                            {headline.sublabel && <div className="sub">{headline.sublabel}</div>}
                        </div>
                    </TableSortLabel>
                </TableCell>
            ))}
        </tr>
    }

    renderTable() {
        const { classes, order, orderBy } = this.props;

        return <table className={classes.tableAll} aria-label="simple table">
            <thead className={classes.tableHeader}>
                {this.renderTableHeader()}
            </thead>
            <tbody>
                {this.renderTableRows()}
            </tbody>
          </table>;
      }

    renderErrorMessage(error) {
      return <ErrorMessage error={error} withPadding />
    }

    render() {

      const { classes } = this.props;

      return (
        <div
          className={clsx(classes.container, this.props.error ? classes.containerError : this.props.data ? '' : classes.containerError)}>
          {this.props.error
            ? this.renderErrorMessage(this.props.error)
            : !_.isEmpty(this.props.data)
              ? this.renderTable()
              : this.renderErrorMessage(DEFAULT_EMPTY_SECTION_MESSAGE)}
        </div>
      )
    }
}

export default withStyles(styles, { withTheme: true })(SinglePerformanceTableChart)