import React from "react";
import _ from "lodash";
import TimeRangesButtonsPad from "../../../../../components/TimeRangeButtonsPad";
import useStyles from './styles';
import {RiskReturnChart as BaseRiskReturnChart} from "../../../../../components/Charts/RiskReturnChart";

export const YEARS = {
  YEARS_1: {
    number: 1,
    unit: 'year',
    title: '1 Jahr'
  },
  YEARS_3: {
    number: 3,
    unit: 'year',
    title: '3 Jahre'
  },
  YEARS_5: {
    number: 5,
    unit: 'year',
    title: '5 Jahre'
  },
}

// used to build date range btns for product comparison and validate if data for all years is available
export const YEARS_MAPPING = {
  YEARS_1: '1y',
  YEARS_3: '3y',
  YEARS_5: '5y',
}

// to reuse RiskReturnChart we need mapping with all possible options
const YEARS_MAPPING_ALL_OPTIONS = {
  ...YEARS_MAPPING,
  YTD: 'ytd',
  YEARS_10: '10y',
}

export function RiskReturnChart({products, config, getSeriesXY, getSeriesColor, onSeriesTooltipClick, btnsPadDisabled, onSelectedRiskReturnRangeChanged}) {

  const classes = useStyles();

  const [selectedRange, setSelectedRange] = React.useState(_.last(Object.keys(config)));

  const onRangeChange = (_, __, rangeCode) => {
    setSelectedRange(YEARS_MAPPING_ALL_OPTIONS[rangeCode])
    onSelectedRiskReturnRangeChanged && onSelectedRiskReturnRangeChanged(YEARS_MAPPING_ALL_OPTIONS[rangeCode]);
  }

  if (!products.length) {
    return null
  }

  let sumX = 0;
  let sumY = 0;
  let seriesWithData = 0;

  const series = products.reduce((result, product) => {
    let [x, y] = getSeriesXY(product, selectedRange)

    if (!_.isNaN(x) && !_.isNaN(y)) {
      seriesWithData += 1;
      sumX += x;
      sumY += y;
    }

    result.push({
      name: product.name,
      color: _.isFunction(getSeriesColor) ? getSeriesColor(product) : product.color,
      data: [{
        x: x, y: y, onTooltipClick: _.isFunction(onSeriesTooltipClick) ? onSeriesTooltipClick : product.onTooltipClick
      }],
      shadow: true,
      marker: {
        symbol: 'circle'
      }
    })

    return result
  }, []);

  let mediumX = sumX / (seriesWithData || products.length);
  let mediumY = sumY / (seriesWithData || products.length);

  return (
    <>
      <TimeRangesButtonsPad
        config={config}
        selected={selectedRange}
        className={classes.buttonsPad}
        onRangeChange={onRangeChange}
        disabled={btnsPadDisabled}
      />

      <BaseRiskReturnChart
        classes={classes}
        series={series}
        mediumX={mediumX}
        mediumY={mediumY}
      />
    </>
  )
};

export default RiskReturnChart